/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentRef, forwardRef, ForwardRefRenderFunction, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import usePermissions from '../../hooks/permissions/usePermissions';
import { ClientForm } from '../../models/ClientForm';
import { FavouriteForm } from '../../models/Form';
import { Roles } from '../../models/Role';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import ClientService from '../../services/ClientService';
import LanguageUtils from '../../utils/LanguageUtils';
import { goToClientForm } from '../../utils/NavigationUtils';
import Button, { ButtonType } from '../shared/form-control/Button';
import PlusIcon from '../shared/icon/PlusIcon';
import Tooltip from '../shared/Tooltip';
import NewTaskModal from '../tasks/NewTaskModal';
import { QuickLinkBgColor } from './QuickLinkColors';
import QuickLinkManageModal from './QuickLinkManageModal';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';

type Props = {
  quickLinks: FavouriteForm[];
  refetchQuickLinks: () => void;
};

type Handle = {
  addNewQuickLink: () => void;
  openManageModal: () => void;
};

const QuickLinkWidget: ForwardRefRenderFunction<Handle, Props> = (props, ref) => {
  const { quickLinks, refetchQuickLinks } = props;
  const [toInstantiateForm, setToInstantiateForm] = useState<FavouriteForm | null>(null);
  const manageModal = useRef<ComponentRef<typeof QuickLinkManageModal>>(null);
  const hasPermission = usePermissions();

  const currentClient = useRecoilValue(currentClientAtom);
  const currentUser = useRecoilValue(currentUserAtom);
  const { t } = useTranslation('home-page');
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    addNewQuickLink() {
      manageModal.current?.open(true);
    },
    openManageModal() {
      manageModal.current?.open(false);
    },
  }));

  const onInstantiatedForm = useCallback(
    (clientForm: ClientForm) => {
      setToInstantiateForm(null);
      goToClientForm(clientForm, currentUser, navigate);
    },
    [currentUser, navigate],
  );

  const onSave = useCallback(
    (items: FavouriteForm[]) => {
      if (!currentClient) {
        return;
      }

      ClientService.setClientQuickLinks(items).then(refetchQuickLinks);
    },
    [currentClient, refetchQuickLinks],
  );

  const templateTitle = useMemo(
    () => (!toInstantiateForm ? '' : LanguageUtils.getTranslation('title', toInstantiateForm.translations)),
    [toInstantiateForm],
  );

  return (
    <div data-cy="quick-link-widget" className="flex min-h-full flex-col">
      <div className="flex-grow px-1 py-2">
        {quickLinks.map((ql) => (
          <div
            key={ql.id}
            className="hover:bg-accent-light-mid my-1 flex cursor-pointer items-center gap-2 rounded-lg px-1 py-1 transition-colors"
            {...mouseAndKeyboardCallbackProps(() => setToInstantiateForm(ql))}
            data-cy="quick-link"
          >
            <div className={`${(QuickLinkBgColor as any)[ql.color]} h-4 w-4 flex-shrink-0 rounded-sm`} />
            <Tooltip text={LanguageUtils.getTranslation('title', ql.translations)} truncatedTextMode>
              {(tooltip) => (
                <button {...tooltip} className="truncate font-medium underline">
                  {LanguageUtils.getTranslation('title', ql.translations)}
                </button>
              )}
            </Tooltip>
          </div>
        ))}

        {quickLinks.length === 0 &&
          (hasPermission(Roles.Management) ? (
            <div>
              <Button onClick={() => manageModal.current?.open(true)} type={ButtonType.TERTIARY}>
                <Button.Slot name="Icon">
                  <PlusIcon className="h-4 w-4" />
                </Button.Slot>
                {t('quick-links.empty.add-quicklink-cta')}
              </Button>
            </div>
          ) : (
            <div className="flex h-full flex-col items-center justify-center">
              <div className="text-color-on-first-primary-1">{t('quick-links.empty.heading')}</div>
              <div className="text-dpm-12">{t('quick-links.empty.text')}</div>
            </div>
          ))}
      </div>
      <div className="sticky bottom-0 flex min-h-[50px] flex-shrink-0 flex-col items-center justify-center bg-white">
        <Button onClick={() => navigate(`/clients/${currentClient?.id}/modules`)} type={ButtonType.SECONDARY}>
          {t('quick-links.go-to-modules')}
        </Button>
      </div>

      <NewTaskModal
        templateId={toInstantiateForm?.templateFormId || ''}
        templateFormName={templateTitle}
        modalHeader={templateTitle}
        onClose={() => setToInstantiateForm(null)}
        open={!!toInstantiateForm}
        onCreated={onInstantiatedForm}
        defaults={toInstantiateForm?.configuration}
      />

      {hasPermission(Roles.Management) && <QuickLinkManageModal ref={manageModal} quickLinks={quickLinks} onSave={onSave} />}
    </div>
  );
};

export default forwardRef(QuickLinkWidget);
