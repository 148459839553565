import { ComponentRef, forwardRef, ForwardRefRenderFunction, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FavouriteForm } from '../../models/Form';
import { QuickLinkManage } from './QuickLinkManageDnd';
import { v4 as uuid } from 'uuid';
import { GroupBase } from 'react-select';
import { Item } from '../shared/form-control/DropdownDefaultComponents';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import ClientTemplateModuleService from '../../services/ClientTemplateModuleService';
import { createFormTemplateSource } from '../form/action-types/FormTemplateSource';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import { FormType } from '../../models/FormTypes';

type Props = {
  quickLinks: FavouriteForm[];
  onSave: (quickLinks: FavouriteForm[]) => void;
};

type Handle = {
  open: (addNewEntry: boolean) => void;
};

const INTERNAL_PREFIX = '_INTERNAL_';

type FavouriteFormWithId = FavouriteForm & { id: string };

const QuickLinkManageModal: ForwardRefRenderFunction<Handle, Props> = (props, ref) => {
  const { quickLinks, onSave } = props;
  const [open, setOpen] = useState(false);
  const [focusLast, setFocusLast] = useState(false);
  const [internalQuickLinks, setInternalQuickLinks] = useState<FavouriteFormWithId[]>([]);
  const [templateForms, setTemplateForms] = useState<GroupBase<Item>[]>([]);
  const [savingAllowed, setSavingAllowed] = useState(true);
  const quickLinksRef = useRef<ComponentRef<typeof QuickLinkManage>>(null);

  const { t } = useTranslation('home-page');
  const currentClient = useRecoilValue(currentClientAtom);

  // reset on cancel
  useEffect(() => {
    if (!open) {
      setInternalQuickLinks(quickLinks.map((x) => ({ ...x, id: x.id || INTERNAL_PREFIX + uuid() })));
    } else {
      quickLinksRef.current?.validate();
    }
  }, [open, quickLinks]);

  useEffect(() => {
    if (currentClient) {
      new ClientTemplateModuleService(currentClient.id)
        .getAllTemplateForms({ types: [FormType.Asset, FormType.Default, FormType.Startup, FormType.SubForm, FormType.SubFormWithApproval] })
        .then((res) => {
          setTemplateForms(createFormTemplateSource(res.data));
        });
    }
  }, [currentClient]);

  useImperativeHandle(ref, () => ({
    open(addNewEntry) {
      setOpen(true);
      if (addNewEntry) {
        setTimeout(() => {
          addNew();
        }, 500);
      }
    },
  }));

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const addNew = useCallback(() => {
    setFocusLast(true);
    setTimeout(() => {
      setFocusLast(false);
    }, 100);
    setInternalQuickLinks((prev) => [
      ...prev,
      {
        id: '_INTERNAL_' + uuid(),
        color: 'Navy',
        sortOrder: prev.length,
        title: '',
        templateFormId: '',
        translations: {},
      },
    ]);
  }, []);

  const onDragEnd = useCallback(
    (values: FavouriteFormWithId[]) => {
      if (!savingAllowed) {
        setSavingAllowed(!!quickLinksRef.current?.validate());
      }

      setInternalQuickLinks(values.map((x, i) => ({ ...x, sortOrder: i })));
    },
    [savingAllowed],
  );

  const onSaveInternal = useCallback(() => {
    if (!quickLinksRef.current?.validate()) {
      setSavingAllowed(false);
      return;
    }

    setSavingAllowed(true);
    onSave(internalQuickLinks.map((x) => ({ ...x, id: x.id?.startsWith(INTERNAL_PREFIX) ? undefined : x.id })));
  }, [internalQuickLinks, onSave]);

  const onItemChange = useCallback((item: FavouriteFormWithId) => {
    setSavingAllowed(true);
    setInternalQuickLinks((prev) => prev.map((ql) => (ql.id === item.id ? item : ql)));
  }, []);

  return (
    <ModalContext.Provider value={{ open: open, onClose: onClose }}>
      <StandardModal
        title={t('quick-links.manage.title')}
        subTitle={t('quick-links.manage.subtitle')}
        cancelButtonTitle={t('quick-links.manage.buttons.cancel')}
        confirmButtonTitle={t('quick-links.manage.buttons.save')}
        tertiaryButtonTitle={t('quick-links.manage.buttons.add')}
        onCancelClick={onClose}
        onConfirmClick={onSaveInternal}
        onTertiaryButtonClick={addNew}
        confirmDisabled={!savingAllowed}
      >
        <div className="max-h-[60vh] min-h-[30vh] flex-grow overflow-auto">
          <QuickLinkManage
            ref={quickLinksRef}
            quickLinks={internalQuickLinks}
            templateForms={templateForms}
            onDragEnd={onDragEnd}
            onItemChange={onItemChange}
            focusLast={focusLast}
          />
        </div>
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default forwardRef(QuickLinkManageModal);
