/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useMemo, useState } from 'react';
import { ControlProps, DropdownIndicatorProps, SingleValueProps } from 'react-select';
import { FCWithChildren } from '../../types/FCWithChildren';
import { Item } from '../shared/form-control/DropdownDefaultComponents';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import DropDownIcon from '../shared/icon/composite/DropDownIcon';
import { ColourOptions, QuickLinkBgColor } from './QuickLinkColors';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string;
  onChange: (newValue: string) => void;
};

const ControlRenderer: FC<ControlProps<Item, false>> = (props) => {
  const { innerProps, innerRef, children } = props;
  return (
    <div ref={innerRef} {...innerProps} className="w-16gfc relative flex justify-center overflow-hidden rounded-md">
      {children}
    </div>
  );
};

const ListRenderer: FC<Item> = (props) => {
  const { id } = props;
  return (
    <div
      onClick={() => document.dispatchEvent(new Event('picked-item'))}
      className={`${(QuickLinkBgColor as any)[id ?? 'Navy']} -ml-[2px] h-6 w-6 cursor-pointer rounded-md`}
    />
  );
};

const WrapperRenderer: FCWithChildren = (props) => {
  return <div className="">{props.children}</div>;
};

const ChevronRenderer: FC<DropdownIndicatorProps<Item, false>> = (props) => {
  const { innerProps, isFocused } = props;

  // This is black magic, I am sorry
  // Basicly we toggle the open-ness of the chevron when clicking on it multiple times,
  // the dropdown will open/close correctly, but the `isFocused` property will still be true
  const [open, setOpen] = useState(isFocused);
  const [sameButtonClick, setSameButtonClick] = useState(!open);

  useEffect(() => {
    setOpen(isFocused);
  }, [isFocused]);

  useEffect(() => {
    const value = open;
    return () => setSameButtonClick(value);
  }, [open]);

  useEffect(
    function detectClose() {
      if (!open) {
        return;
      }

      const pickHandler = () => {
        setOpen(false);
      };

      const clickHandler = () => {
        setSameButtonClick((prev) => !prev);
      };

      document.addEventListener('picked-item', pickHandler);
      document.addEventListener('click', clickHandler);
      return () => {
        document.removeEventListener('picked-item', pickHandler);
        document.removeEventListener('click', clickHandler);
      };
    },
    [open],
  );

  return (
    <div {...innerProps}>
      <DropDownIcon
        data-cy="dropdown-icon"
        className="text-1 absolute left-[1px] h-5 w-5 flex-shrink-0 -translate-y-1/2 transform cursor-pointer text-white"
        open={open && sameButtonClick}
      />
    </div>
  );
};

const ControlPlaceholder: FC<SingleValueProps<Item, false>> = (props) => {
  const { innerProps, data } = props;
  return <div {...innerProps} className={`${(QuickLinkBgColor as any)[data.id ?? 'Navy']} h-6 w-6 cursor-pointer rounded-md`} />;
};

const QuickLinkColourPicker: FC<Props> = (props) => {
  const { value, onChange } = props;
  const { t } = useTranslation('common');

  const options = useMemo<Item[]>(
    () =>
      ColourOptions.map((x) => ({
        id: x,
        text: x,
        value: x,
      })),
    [],
  );

  const currentOption = useMemo(() => options.find((x) => x.id === value), [options, value]);

  return (
    <div className="w-6 flex-shrink-0">
      <DropdownSelect
        options={options}
        value={currentOption}
        onChange={(o) => onChange(o.id)}
        customListRenderer={ListRenderer}
        dropdownListWrapper={WrapperRenderer}
        customComponents={{ Control: ControlRenderer, DropdownIndicator: ChevronRenderer, SingleValue: ControlPlaceholder }}
        aria-label={t('aria-label.colour-picker')}
      />
    </div>
  );
};

export default QuickLinkColourPicker;
